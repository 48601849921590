@use "./config/" as *;
@forward "./main-scss/";
@forward "./listing/common";
.waf-listing {
    --_listing-line: 3;
    --_listing-title: 14;
    @include listing-card(horizontal);
    .article {
        &-item {
            @extend %mb-4;
        }
    }
}
@include mq(col-md) {
    .waf-listing {
        --_listing-line: 2;
        --_listing-title: 16;
        @include listing-card();
        .article {
            &-content {
                margin: 0;
                padding: var(--space-2) 0;
                color: var(--neutral-800)
            }
        }
    }
}